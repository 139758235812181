<template>
  <!-- TradingView Widget BEGIN -->
  <div class="tradingview-widget-container" ref="tvContainer">
    <div class="tradingview-widget-container__widget"></div>
  </div>
  <!-- TradingView Widget END -->
</template>

<script>

export default {
  name: 'TVTechnicalAnalysisContainer',

  props: ['id', 'name', 'code'],

  mounted() {
    let self = this;
    var options = {
        "interval": "15m",
        "width": 377,
        "isTransparent": false,
        "height": 400,
        "symbol": self.code,
        "showIntervalTabs": true,
        "locale": "in",
        "colorTheme": "light"
    };
    var str = JSON.stringify(options, null, 2);
    var script = document.createElement('script');
    script.setAttribute("type", "text/javascript");
    script.setAttribute("src", "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js")
    script.setAttribute("async", true);
    script.text = str;
    this.$refs.tvContainer.appendChild(script);
  }
}
</script>
