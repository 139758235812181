<template>
  <div id="container-list" class="ml-2">
    <b-row class="justify-content-md-center">
      <b-col col lg="2"></b-col>
      <b-col cols="12" md="auto">
        <b-button class="m-1" variant="outline-primary" size="sm"
          :disabled="pageNumber === 0" 
          @click="prevPage">
          Previous
      </b-button>

      <b-button class="m-1" variant="outline-primary" size="sm"
          :disabled="pageNumber >= pageCount -1" 
          @click="nextPage">
          Next
      </b-button>
      </b-col>
      <b-col col lg="2"></b-col>
    </b-row>
    <b-row class="justify-content-md-center mt-10">
      <b-card-group deck>
        <div v-for="symbol in paginatedData" 
          v-bind:key="symbol.id">
          <div class="mb-1">
            <b-card :title="symbol.symbolShort">
              <b-row no-gutters class="justify-content-md-center">
                <TVSymbolInfoContainer
                  v-bind:id="symbol.id"
                  v-bind:name="symbol.symbolShort"
                  v-bind:code="symbol.symbolFull" />
              </b-row>
              <b-row no-gutters class="justify-content-md-center">
                <TVTechnicalAnalysisContainer
                  v-bind:id="symbol.id"
                  v-bind:name="symbol.symbolShort"
                  v-bind:code="symbol.symbolFull" />
              </b-row>
            </b-card>
          </div>
        </div>
      </b-card-group>
    </b-row>
    <b-row class="justify-content-md-center">
      <b-col col lg="2"></b-col>
      <b-col cols="12" md="auto">
        <b-button class="m-1" variant="outline-primary" size="sm"
          :disabled="pageNumber === 0" 
          @click="prevPage">
          Previous
      </b-button>

      <b-button class="m-1" variant="outline-primary" size="sm"
          :disabled="pageNumber >= pageCount -1" 
          @click="nextPage">
          Next
      </b-button>
      </b-col>
      <b-col col lg="2"></b-col>
    </b-row>
  </div>
</template>
<script>

import TVSymbolInfoContainer from './TVSymbolInfoContainer.vue'
import TVTechnicalAnalysisContainer from './TVTechnicalAnalysisContainer.vue'

export default {
  name: 'TVChartContainerList',

  props: ['symbols'],

  data() {
    return {
      pageNumber: 0,
      size: 3
    };
  },

  computed: {
    pageCount(){
      let l = this.symbols.length,
          s = this.size;
      return Math.ceil(l/s);
    },

    paginatedData(){
      const start = this.pageNumber * this.size,
            end = start + this.size;
      return this.symbols
               .slice(start, end);
    }
  },

  methods: {
    nextPage(){
         this.pageNumber++;
    },
    prevPage(){
      this.pageNumber--;
    }
  },

  components: {
    TVSymbolInfoContainer,
    TVTechnicalAnalysisContainer
  }
}
</script>