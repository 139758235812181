<template>
  <!-- TradingView Widget BEGIN -->
  <div class="tradingview-widget-container" ref="tvSymbolContainer">
    <div :id="'tradingview_' + id"></div>
  </div>
<!-- TradingView Widget END -->
</template>

<script>

export default {
  name: 'TVSymbolInfoContainer',

  props: ['id', 'name', 'code'],

  mounted() {
    let self = this;
    var options = {
      "symbol": self.code,
      "width": "100%",
      "locale": "en",
      "colorTheme": "light",
      "isTransparent": false
    };
    var str = JSON.stringify(options, null, 2);
    var script = document.createElement('script');
    script.setAttribute("type", "text/javascript");
    script.setAttribute("src", "https://s3.tradingview.com/external-embedding/embed-widget-symbol-info.js")
    script.setAttribute("async", true);
    script.text = str;
    this.$refs.tvSymbolContainer.appendChild(script);
  }
}
</script>
