<template>
  <div id="app">
    <b-container fluid>
      <b-row>
        <b-col lg="4" class="pb-2">
          <div class="search-wrapper ml-1">
            <b-form inline>
              <b-form-input class="m-1" type="text" v-model="search" placeholder="Search .."/>
              <b-button class="m-1" variant="outline-primary" size="sm" @click="resetInput">Reset</b-button>
            </b-form>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12" class="pb-2">
          <TVChartContainerList  v-bind:symbols="filteredList" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import TVChartContainerList from './components/TVChartContainerList.vue'
import symbols from './assets/tradingview-watchlist.json'

export default {
  name: 'App',

  components: {
    TVChartContainerList,
  },

  data() {
    return {
      search: '',
      symbols: symbols
    };
  },

  computed: {
    filteredList() {
      var self = this;
      console.log(self.search);
      return self.symbols.filter(symbol => {
        return symbol.symbolShort.toLowerCase().includes(self.search.toLowerCase());
      });
    }
  },

  methods: {
    resetInput() {
      this.search = '';
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
